/**
 * MAIN JS
 * @author frédéric Pineau
 * par artwaï pour artwaï
 */

const GEN_SHARELINK_WINDOW_JS_URI = 'scripts/sharelink_window_4ai.47fe2278.js';
const GEN_PWA_INSALL_JS_URI = 'scripts/pwa_install_4ai.9bddef1f.js';
const GEN_ONE_SUBMIT_JS_URI = 'scripts/one_submit_4ai.68a9c6b2.js';

const themePath = "/wp-content/themes/artek/dist/";

/*
const sharelinks = document.querySelectorAll('.share-links');
if (sharelinks && sharelinks.length > 0) {
    new loadExt([themePath + GEN_SHARELINK_WINDOW_JS_URI]);
}

const pwa_install_btn = document.querySelectorAll('.pwa-install-btn');
if (pwa_install_btn && pwa_install_btn.length > 0) {
    new loadExt([themePath + GEN_PWA_INSALL_JS_URI]);
}

const forms = document.querySelectorAll('form');
if (forms && forms.length > 0) {
    new loadExt([themePath + GEN_ONE_SUBMIT_JS_URI]);
}*/

window.collections = {};

const SCRIPTS = [
    {
        'url' : GEN_SHARELINK_WINDOW_JS_URI,
        'sel' : '.share-links a',
        'col' : 'sharelinks',
        'lazy': true
    },
    {
        'url' : GEN_PWA_INSALL_JS_URI,
        'sel' : '.pwa-install-btn',
        'col' : 'addBtn',
        'lazy': false
    },
    {
        'url' : GEN_ONE_SUBMIT_JS_URI,
        'sel' : 'form',
        'col' : 'forms',
        'lazy': true
    }
];

/*let selectors = [];
for(let script of SCRIPTS ) {
    selectors.push(script.sel);
}*/
//const obj_present = Array.from(document.querySelectorAll( selectors.join(',') ) );

var obsOptions = {
    root: null,
    rootMargin: '0px',
    threshold: 1.0
}

for(let script of SCRIPTS ) {
    collections[script.col] = Array.from(document.querySelectorAll(script.sel));
    if(collections[script.col].length > 0) {
        if(script.lazy) {
            let encours = false,
                observer = new IntersectionObserver(function(entries) {
                for (let j = 0, lg = entries.length; j < lg; j++) {
                    // console.log(script.sel);
                    if (entries[j].intersectionRatio <= 0 || encours) return;
                    encours = true;
                    new loadExt([themePath+script.url]);
                    for (let k = 0, lgt = entries.length; k < lgt; k++) {
                        observer.unobserve(entries[k].target);
                    }
                }
            }, obsOptions);

            for (let i = 0, l = collections[script.col].length; i < l; i++) {
                observer.observe(collections[script.col][i]);
            }

        } else {
            new loadExt([themePath+script.url]);
        }
    }
}

(function () {
    // Popin
    window.addEventListener('load', function() {
        const popin = document.getElementById('popin');
        if(popin) {
            if(localStorage.getItem('popin') != 1) {
                popin.checked = true;
                localStorage.setItem('popin', 1);
            }
        }
    });

}());

(function () {
    // Search engine animation
    /* Refactoriser ? */
    var inputTags = document.getElementById("open-tags"),
    searchInput = document.getElementById("s");
    var fakePlaceholder;
    if(inputTags) {
        var searchPlaceholder = searchInput.getAttribute('placeholder');
        fakePlaceholder = document.getElementById('search-form').getElementsByClassName('fake-placeholder');
        inputTags.addEventListener('change', function() {
            if(inputTags.checked) {
                searchInput.setAttribute('placeholder',"Sélectionnez votre domaine d’activité");
                fakePlaceholder[0].textContent = "Sélectionnez votre domaine d’activité";
            } else {
                searchInput.setAttribute('placeholder',searchPlaceholder);
                fakePlaceholder[0].textContent = searchPlaceholder;
            }
            searchInput.focus();
        });
    }
    if(searchInput) {
        fakePlaceholder = document.getElementById('search-form').getElementsByClassName('fake-placeholder');
        if(searchInput.value != '') {
            fakePlaceholder[0].style.display = "none";
        }
        searchInput.addEventListener('change',function() {
            if(this.value != '') {
                fakePlaceholder[0].style.display = "none";
            } else {
                fakePlaceholder[0].style.display = "";
            }
        });
    }
}());

(function() {
    // Put formation data in subscription form
    var txtFormation = document.getElementById("txt-formation"),
    formationInfos = document.getElementById("formation-infos"),
    session = document.getElementById("id_session"),
    sessionAlt = document.getElementById("id_session_alt"),
    inputFormation = document.getElementById("input-formation"),
    inputSession = document.getElementById("input-session"),
    secondSubmit = document.getElementById('inscription_alt'),
    sessionBackup = document.getElementById('id_session_backup'),
    formationBackup = document.getElementById('id_formation_alt');
    if(formationInfos) {
        if(txtFormation) {
            inputFormation.value = txtFormation.textContent.replace(/(<([^>]+)>)/ig,"");
            inputSession.value = session.options[session.selectedIndex].text.replace(/(<([^>]+)>)/ig,"");
            localStorage.setItem("formation",txtFormation.textContent.replace(/(<([^>]+)>)/ig,""));
            if(sessionAlt) {
                sessionAlt.value = session.value;
            }
            if(sessionBackup) {
                sessionBackup.value = session.value;
            }
        } else {
            if(localStorage.getItem('formation')) {
                inputFormation.value = localStorage.getItem('formation');
                inputSession.value = localStorage.getItem('session');
                if(sessionAlt) {
                    sessionAlt.value = session.value;
                }
                if(sessionBackup) {
                    sessionBackup.value = session.value;
                }
            }
        }
    }

    if(session) {
        session.options[0].setAttribute('disabled','disabled');
        if(sessionAlt) {
            sessionAlt.value = session.value;
        }
        if(sessionBackup) {
            sessionBackup.value = session.value;
        }

        if(sessionBackup && formationBackup) {
            var idFormation = document.getElementById('id_formation');
            formationBackup.value = idFormation.value;
        }
        localStorage.setItem("session",session.options[session.selectedIndex].text.replace(/(<([^>]+)>)/ig,""));
        session.addEventListener('change', function() {
            localStorage.setItem("session",this.options[this.selectedIndex].text.replace(/(<([^>]+)>)/ig,""));
            if(sessionAlt) {
                sessionAlt.value = session.value;
            }
            if(inputSession) {
                inputSession.value = this.options[this.selectedIndex].text.replace(/(<([^>]+)>)/ig,"");
            }
            if(sessionBackup) {
                sessionBackup.value = session.value;
            }
        });
    }

    if(secondSubmit) {
        secondSubmit.addEventListener('click', function() {
            if(!session.value) return false;
        });
    }

}());

(function() {
    // Scroll to formation select if empty
    var form = document.getElementById('section-formulaire'),
        submitButton = form ? form.querySelector('button[type="submit"]') : false;
    if(submitButton) {
        submitButton.addEventListener('click', function(e) {
            var sessionB = document.getElementById('id_session_backup');
            if(sessionB && sessionB.value == '') {
                e.preventDefault();
                document.getElementById('formation-infos').classList.add('error');
                if(document.body.scrollIntoView) {
                    var idSession = document.getElementById('id_session');
                    if(idSession) {
                        idSession.scrollIntoView({behavior: "smooth"});
                        idSession.addEventListener('change', function(e) {
                            document.getElementById('formation-infos').classList.remove('error');
                        });
                    } else {
                        document.getElementById('no-formation').scrollIntoView({behavior: "smooth"});
                    }
                }
            }
        });
    }
}());

(function() {
    // Go to subscription page on click (formation list)
    var sessions = document.getElementsByClassName('submit-on-click');
    if(sessions) {
        for(var i = 0; i < sessions.length; i++) {
            (function(index) {
                sessions[index].addEventListener('change', function() {
                    this.closest('form').submit();
                });
            })(i);
        }
    }
}());
(function() {
    // Autoplay carousel
    var sliders = document.querySelectorAll('.carrouselmod.listofposts');
    function doSetTimeout(item) {
        var slide = setInterval(function() {
            var inputs = item.querySelectorAll("input.ctrl");
            if(inputs.length > 0) {
                for( var j = 0; j < inputs.length; j++) {
                    if(inputs[j].checked) {
                        var pas = 1;
                        if(window.innerWidth > 960) {
                            if(inputs[j].dataset.pas) {
                                pas = parseInt(inputs[j].dataset.pas);
                            }
                        }
                        if(inputs[j+pas]) {
                            inputs[j+pas].checked = true;
                            break;
                        } else {
                            inputs[0].checked = true;
                            j = 0;
                        }

                    }
                }
            }
        }, 6000);
    }

    if(sliders) {
        for (var i = 0; i < sliders.length; i++) {
            doSetTimeout(sliders[i]);
        }
    }
}());
